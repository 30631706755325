import { FC, useState } from 'react'
import Config from '../lib/Config'
import { usePayment } from '../lib/Hooks/Payment'
import Headline from './Elements/Headline'
import { Modal } from './Elements/Modal'
import Text from './Elements/Text'
import Button from './Formik/Button'
import { SheetType } from './Sheets/lib/Enums/SheetType'
import { useCreateBudgetFromTemplate } from './Sheets/lib/Hooks/Budget'

type Props = {
  show: boolean
  onHide: () => void
  sheetType: SheetType
  sheetId?: number
  children: any
}
const Paywall: FC<Props> = ({ show, onHide, sheetType, sheetId, children }) => {
  const [paymentComplete, togglePayment] = useState(false)

  const { mutateAsync: pay, isPending: isPaymentLoading } = usePayment()
  const { mutateAsync: createBudget } = useCreateBudgetFromTemplate()

  const sendPayment = async () => {
    const sheet = { sheetType, id: sheetId }

    if (!sheet.id) {
      const budget = await createBudget()
      sheet.id = budget.id
    }

    await pay(sheet as { sheetType: SheetType; id: number })

    togglePayment(true)
  }

  return (
    <Modal show={show} onHide={onHide}>
      {(closeModal: any) => (
        <>
          {!paymentComplete && (
            <>
              {typeof children === 'function' && children(closeModal)}
              {typeof children === 'object' && children}
              <Button onClick={sendPayment} loading={isPaymentLoading}>
                Betal{' '}
                {sheetType === SheetType.BUDGET
                  ? Config.PRICE_BUDGET
                  : Config.PRICE_ACCOUNTING}
                ,- kr nu
              </Button>
            </>
          )}
          {paymentComplete && (
            <>
              <Headline>Tak for din støtte 🙏🏼</Headline>
              <Text className='mt-3'>
                Tak for din støtte til Privat Økonomi, det sætter vi stor pris
                på!
              </Text>
              <Text className='mt-3'>
                Du vil om kort tid modtage et link til betaling af{' '}
                {sheetType === SheetType.ACCOUNTING
                  ? 'regnskabet'
                  : 'budgettet'}
                . Når du har gennemført betalingen, vil du have adgang til{' '}
                {sheetType === SheetType.ACCOUNTING
                  ? 'regnskabet'
                  : 'budgettet'}{' '}
                i et år.
              </Text>
              <Text className='mt-3'>
                Hvis du har spørgsmål, er du altid velkommen til at kontakte os
                på kontakt@privat-økonomi.dk.
              </Text>
              <Button className='mt-6' onClick={closeModal}>
                Luk vindue
              </Button>
            </>
          )}
        </>
      )}
    </Modal>
  )
}

export default Paywall
