import { Suspense, useContext, useEffect, useState } from 'react'
import {
  MdArrowDropDown,
  MdArrowRight,
  MdOutlineReceiptLong,
} from 'react-icons/md'
import { SidebarContext } from '../../../lib/Context'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import {
  getDateFromKey,
  getMonthNameFromNumber,
  getWeek,
} from '../../../lib/Dates'
import { Spend } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import LoadingSpinner from '../../Elements/LoadingSpinner'
import Button from '../../Formik/Button'
import { AccountingType } from '../lib/Enums/AccountingType'
import { AccountingViewMode } from '../lib/Enums/AccountingViewMode'
import {
  useAccounting,
  useAccountingBalance,
  useAccountingBalances,
} from '../lib/Hooks/Accounting'
import { useCategories } from '../lib/Hooks/Categories'
import WelcomeAccounting from '../WelcomeAccounting'
import './Accounting.css'
import AccountingMenu from './AccountingMenu'
import AddSpend from './AddSpend'
import Categories from './Categories'
import Expenses from './Expenses'
import ViewCategories from './ViewCategories'
import ViewSpends from './ViewSpends'

const summarizeExpenses = (spends: Spend[]) => {
  let total = 0

  const spendsToSummarize = spends.filter(
    (spend) => !!spend.is_expense && !spend.expense_paid,
  )

  spendsToSummarize.forEach((spend) => {
    total += spend.amounts.at(-1)!.amount
  })

  return total
}

const AccountingComponent = () => {
  const { data: accounting } = useAccounting()
  const { data: categories } = useCategories(accounting!)
  const { data: balances } = useAccountingBalances(accounting!)
  const { data: balance } = useAccountingBalance(accounting!)

  const getSpendGroupKey = (accountingDate: Date): number => {
    const year = accountingDate.getFullYear()

    if (accounting!.type === AccountingType.YEAR) {
      return year
    } else if (accounting!.type === AccountingType.MONTH) {
      const month = accountingDate.getMonth() + 1
      return parseInt(`${year}${month}`, 10)
    } else if (accounting!.type === AccountingType.WEEK) {
      const week = getWeek(accountingDate)

      // The first week of a new year, might start on a date in the previous year.
      // Handle this edge case, by comparing the month of the date if week is 01.
      return week === 1 && accountingDate.getMonth() === 11
        ? parseInt(`${year + 1}${week}`, 10)
        : parseInt(`${year}${week}`, 10)
    }
    return 0
  }

  const { showSidebar } = useContext(SidebarContext)
  const [groups, setGroups] = useState<Map<number, boolean>>(new Map())

  // Build the maps of spends to render.
  useEffect(() => {
    const spendGroups = new Map<number, boolean>()

    // Split the balances into groups
    Object.keys(balances!).forEach((key) => {
      const groupKey = parseInt(key, 10)
      if (!spendGroups.has(groupKey)) {
        spendGroups.set(
          groupKey,
          groups.has(groupKey) ? groups.get(groupKey)! : false,
        )
      }
    })

    // Find the group with the lowest key.
    const firstGroup = Array.from(spendGroups.keys()).reduce((lowest, key) => {
      return Math.min(lowest, key)
    }, Infinity)

    // Make sure to render every month or week from the earliest group hithero.
    if (firstGroup !== Infinity) {
      // The end key will always be the key generated from the current date.
      const endKey = getSpendGroupKey(new Date())

      const startDate = getDateFromKey(accounting!, firstGroup)
      let currentKey = getSpendGroupKey(startDate)

      while (currentKey < endKey) {
        const currentDate = getDateFromKey(accounting!, currentKey)

        if (accounting!.type === AccountingType.WEEK) {
          currentDate.setDate(currentDate.getDate() + 7)
        } else if (accounting!.type === AccountingType.MONTH) {
          currentDate.setMonth(currentDate.getMonth() + 1)
        } else if (accounting!.type === AccountingType.YEAR) {
          currentDate.setFullYear(currentDate.getFullYear() + 1)
        }

        currentKey = getSpendGroupKey(currentDate)

        if (!spendGroups.has(currentKey)) {
          spendGroups.set(
            currentKey,
            groups.has(currentKey) ? groups.get(currentKey)! : false,
          )
        }
      }

      // By default make the first group expanded
      spendGroups.set(endKey, groups.has(endKey) ? groups.get(endKey)! : true)
    }

    // If no first group exists, it's an empty accounting sheet. Add one group.
    if (
      firstGroup === Infinity ||
      !spendGroups.has(getSpendGroupKey(new Date()))
    ) {
      const firstGroupKey = getSpendGroupKey(new Date())
      spendGroups.set(
        firstGroupKey,
        groups.has(firstGroupKey) ? groups.get(firstGroupKey)! : true,
      )
    }

    // Order the groups by ascending key
    const orderedSpendGroups = new Map(
      [...spendGroups.entries()].sort((a, b) => {
        const groupA = a[0].toString().match(/^(\d{4})(.*)/)!
        const groupB = b[0].toString().match(/^(\d{4})(.*)/)!

        const yearA = parseInt(groupA[1], 10)
        const kickerA = parseInt(groupA[2], 10)

        const yearB = parseInt(groupB[1], 10)
        const kickerB = parseInt(groupB[2], 10)

        if (yearA !== yearB) {
          return yearA - yearB
        } else {
          return kickerA - kickerB
        }
      }),
    )

    setGroups(orderedSpendGroups)
  }, [balances])

  const getStartDateFromKey = (key: number): Date => {
    const date = getDateFromKey(accounting!, key)
    let newDate = new Date(date)

    if (accounting!.type === AccountingType.WEEK) {
      // Find the first day of the week from the date.
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is Sunday
      newDate.setDate(diff)
    } else if (accounting!.type === AccountingType.MONTH) {
      newDate.setDate(1)
    } else if (accounting!.type === AccountingType.YEAR) {
      newDate.setMonth(0)
      newDate.setDate(1)
    } else {
      newDate = new Date(accounting!.created_date)
    }

    return newDate
  }

  const getEndDateFromKey = (key: number): Date => {
    const date = getDateFromKey(accounting!, key)
    let newDate = new Date(date)

    if (accounting!.type === AccountingType.WEEK) {
      // Find the last day of the week from the date.
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is Sunday
      newDate.setDate(diff + 6) // Add 6 to get the last day of the week
    } else if (accounting!.type === AccountingType.MONTH) {
      newDate.setMonth(date.getMonth() + 1) // Go to the next month
      newDate.setDate(0) // Go back one day to the last day of the previous month
    } else if (accounting!.type === AccountingType.YEAR) {
      newDate.setFullYear(date.getFullYear() + 1) // Go to the next year
      newDate.setMonth(0) // Go to January
      newDate.setDate(0) // Go back one day to the last day of the previous year
    } else {
      newDate = new Date()
      newDate.setFullYear(newDate.getFullYear() + 10)
    }

    return newDate
  }

  // Use the color to indicate the balance for the group.
  const getBalanceColor = (balanceForGroup: number): string => {
    if (!accounting!.budget) return 'text-black'

    const calculatedBalance = accounting!.budget - balanceForGroup

    if (calculatedBalance < 0) {
      return 'text-red-400'
    } else if (calculatedBalance < 300) {
      return 'text-yellow-600'
    }
    return 'text-green-700'
  }

  return (
    <>
      <WelcomeAccounting sheet={accounting!} show={!accounting!.is_paid} />
      <div className='h-40 md:h-28'>
        <div className='fixed w-full top-0 left-0 mt-16 bg-white z-10'>
          <div className='pt-4 px-8 mx-auto max-w-7xl'>
            <Headline>{accounting!.name || 'Regnskab'}</Headline>
            {accounting!.description && (
              <p className={'italic '}>{accounting!.description}</p>
            )}
            <AccountingMenu />
            {accounting!.dedicated_account && (
              <p className='mb-3'>
                Nuværende saldo: {<strong>{formatCurrency(balance!)}</strong>}
              </p>
            )}
            {/* {summarizeExpenses(spends!) > 0 && ( */}
            {/*   <p className='-mt-2 mb-3'> */}
            {/*     Udlæg i alt:{' '} */}
            {/*     <strong>{formatCurrency(summarizeExpenses(spends!))}</strong> */}
            {/*   </p> */}
            {/* )} */}
          </div>
        </div>
      </div>
      <div className='overflow-auto'>
        <div className='table min-w-full'>
          {[...groups.entries()].reverse().map(([key, expanded]) => (
            <div key={key}>
              <table className='min-w-full'>
                <thead className={'bg-gray-300 border border-gray-400'}>
                  <tr>
                    <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                      <div className='flex items-center'>
                        <div
                          className='flex cursor-pointer'
                          onClick={() => {
                            const newGroups = structuredClone(groups)
                            const expanded = newGroups.get(key)

                            newGroups.set(key, !expanded)

                            setGroups(newGroups)
                          }}
                        >
                          {accounting!.type !== AccountingType.REGULAR && (
                            <div>
                              {expanded && <MdArrowDropDown size='1.25rem' />}
                              {!expanded && <MdArrowRight size='1.25rem' />}
                            </div>
                          )}
                          {accounting!.type === AccountingType.YEAR && (
                            <span className='grow mr-10 font-bold'>{key}</span>
                          )}
                          {accounting!.type === AccountingType.MONTH && (
                            <span className='grow mr-10 font-bold'>
                              {`${getMonthNameFromNumber(getDateFromKey(accounting!, key).getMonth() + 1)} ${getDateFromKey(accounting!, key).getFullYear()}`}
                            </span>
                          )}
                          {accounting!.type === AccountingType.WEEK && (
                            <span className='grow mr-10 font-bold'>
                              Uge{' '}
                              {`${getWeek(getDateFromKey(accounting!, key))} / ${getDateFromKey(accounting!, key).getFullYear()}`}
                            </span>
                          )}
                        </div>
                        <div className='grow flex'>
                          <p
                            className={`mr-10 ${getBalanceColor(balances![key])}`}
                          >
                            {`Balance: ${formatCurrency(balances![key] || 0)} ${accounting!.budget > 0 ? `/ ${formatCurrency(accounting!.budget)}` : ''}`}
                          </p>
                          {/* {summarizeExpenses(group.spends) > 0 && ( */}
                          {/*   <p> */}
                          {/*     Udlæg:{' '} */}
                          {/*     {formatCurrency(summarizeExpenses(group.spends))} */}
                          {/*   </p> */}
                          {/* )} */}
                        </div>
                        <Button
                          onClick={() =>
                            showSidebar(
                              <Expenses
                                from={getStartDateFromKey(key)}
                                to={getEndDateFromKey(key)}
                              />,
                            )
                          }
                          theme='blue'
                          size='sm'
                          className='mr-2'
                        >
                          <div className='flex items-center'>
                            <MdOutlineReceiptLong className='mr-2' />
                            Vis udlæg
                          </div>
                        </Button>
                        <AddSpend
                          accountingDate={
                            getSpendGroupKey(new Date()) === key
                              ? new Date()
                              : getEndDateFromKey(key)
                          }
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              {expanded && (
                <Suspense
                  fallback={
                    <table className='min-w-full table-accounting-3'>
                      <tbody>
                        <tr className='bg-gray-100 border-b border-x border-gray-300'>
                          <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                            <LoadingSpinner />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  }
                >
                  {accounting!.view_mode === AccountingViewMode.CATEGORIES && (
                    <ViewCategories
                      isCurrentPeriod={getSpendGroupKey(new Date()) === key}
                      from={getStartDateFromKey(key)}
                      to={getEndDateFromKey(key)}
                    />
                  )}
                  {accounting!.view_mode === AccountingViewMode.SPENDS && (
                    <>
                      <ViewSpends
                        from={getStartDateFromKey(key)}
                        to={getEndDateFromKey(key)}
                      />
                      {categories!.length > 1 && (
                        <Categories
                          from={getStartDateFromKey(key)}
                          to={getEndDateFromKey(key)}
                        />
                      )}
                    </>
                  )}
                </Suspense>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AccountingComponent
