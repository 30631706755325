import { useState, type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Headline from '../Elements/Headline'
import { Modal } from '../Elements/Modal'
import Text from '../Elements/Text'
import Button from '../Formik/Button'
import { useCreateBudgetFromTemplate } from '../Sheets/lib/Hooks/Budget'
import { TaskType } from './lib/Enums/TaskType'
import { useSkipTask } from './lib/Hooks/Tasks'

const TaskBudgetCreate: FC = () => {
  const [showModal, toggleModal] = useState(false)
  const { mutate: markTaskAsSkipped } = useSkipTask()
  const { mutateAsync: createBudget } = useCreateBudgetFromTemplate()
  const navigate = useNavigate()

  const completeTask = async () => {
    const budget = await createBudget()

    return navigate(`/budget/${budget.id}/`)
  }

  return (
    <>
      <Modal show={showModal} onHide={() => toggleModal(false)}>
        {(closeModal: any) => (
          <>
            <Headline size='2xl' className='mb-4'>
              Jeg har allerede et budget
            </Headline>
            <Text size='base' className='mb-4'>
              Du er ved at vælge, at du allerede har et budget, der ikke er
              oprettet hos Privat Økonomi.
            </Text>
            <Text size='base' className='mb-8'>
              Det er naturligvis helt fint, da det vigtigste er, at du har et
              budget. Et budget hos Privat Økonomi giver dog afgang til rigtig
              mange brugbare funktioner, der giver en bedre indsigt i din
              økonomi. Du kan læse mere om budget hos Privat Økonomi her.
            </Text>
            <Button
              theme='blue'
              onClick={() => {
                markTaskAsSkipped(TaskType.BUDGET_CREATE)
                toggleModal(false)
                closeModal()
              }}
            >
              Bekræft valg
            </Button>
          </>
        )}
      </Modal>
      <div className='flex flex-col justify-between h-[calc(100%-28px)]'>
        <div className='mb-4'>
          <Headline size='3xl' className='mb-2'>
            Opret Budget
          </Headline>
          <Text size='base'>
            Det første skridt på vejen til en stærk økonomi, er at have et
            budget. Et budget giver et godt overblik og sikrer du altid kan
            betale de nødvendige regninger.
          </Text>
        </div>
        <div className='flex justify-between items-center'>
          <Button theme='ghost' onClick={() => toggleModal(true)}>
            Jeg har et budget
          </Button>
          <Button theme='blue' onClick={completeTask}>
            Start nu
          </Button>
        </div>
      </div>
    </>
  )
}

export default TaskBudgetCreate
