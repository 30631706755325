import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Headline from '../Components/Elements/Headline'
import Text from '../Components/Elements/Text'
import Button from '../Components/Formik/Button'
import Guide from '../Components/Guide/Guide'
import CardAccounting from '../Components/Sheets/Accounting/Card'
import CreateAccounting from '../Components/Sheets/Accounting/CreateAccounting'
import CardBudget from '../Components/Sheets/Budget/Card'
import CreateBudget from '../Components/Sheets/Budget/CreateBudget'
import { useAccountings } from '../Components/Sheets/lib/Hooks/Accounting'
import {
  useBudgets,
  useCreateBudgetFromTemplate,
} from '../Components/Sheets/lib/Hooks/Budget'
import { Accounting, Budget } from '../lib/Types'

export default function DashboardPage() {
  const navigate = useNavigate()
  const { data: budgets } = useBudgets()
  const { data: accountings } = useAccountings()
  const { mutateAsync: createBudget } = useCreateBudgetFromTemplate()
  const [showCreateAccounting, toggleShowCreateAccounting] = useState(false)

  const budgetColumn1 = budgets!.filter((_, index) => index % 3 === 0)
  const budgetColumn2 = budgets!.filter((_, index) => index % 3 === 1)
  const budgetColumn3 = budgets!.filter((_, index) => index % 3 === 2)

  const accountingColumn1 = accountings!.filter((_, index) => index % 3 === 0)
  const accountingColumn2 = accountings!.filter((_, index) => index % 3 === 1)
  const accountingColumn3 = accountings!.filter((_, index) => index % 3 === 2)

  return (
    <>
      <Guide />
      <Headline>
        <div className='flex flex-col md:flex-row justify-between md:items-center'>
          Budget
          <CreateBudget />
        </div>
      </Headline>
      <div className='flex flex-wrap md:flex-nowrap justify-between mb-5'>
        <div className='w-full md:w-1/3 mr-0 md:mr-2'>
          {budgets!.length === 0 && (
            <Text size='base'>Du har endnu ikke oprettet et budget.</Text>
          )}
          {budgetColumn1.map((item, index) => (
            <CardBudget key={index} budget={item as Budget} />
          ))}
        </div>
        <div className='w-full md:w-1/3 mx-0 md:mx-2'>
          {budgetColumn2.map((item, index) => (
            <CardBudget key={index} budget={item as Budget} />
          ))}
        </div>
        <div className='w-full md:w-1/3 ml-0 md:ml-2'>
          {budgetColumn3.map((item, index) => (
            <CardBudget key={index} budget={item as Budget} />
          ))}
        </div>
      </div>
      <Headline>
        <div className='flex flex-col md:flex-row justify-between md:items-center'>
          Regnskab
          <Button
            onClick={() => toggleShowCreateAccounting(!showCreateAccounting)}
            className='ml-0 md:ml-2 mb-4 md:mb-0'
          >
            Opret et Regnskab
          </Button>
        </div>
      </Headline>
      <div className='flex flex-wrap md:flex-nowrap justify-between'>
        <div className='w-full md:w-1/3 mr-0 md:mr-2'>
          {accountings!.length === 0 && (
            <Text size='base'>Du har endnu ikke oprettet et regnskab.</Text>
          )}
          {accountingColumn1.map((item, index) => (
            <CardAccounting key={index} accounting={item as Accounting} />
          ))}
        </div>
        <div className='w-full md:w-1/3 mx-0 md:mx-2'>
          {accountingColumn2.map((item, index) => (
            <CardAccounting key={index} accounting={item as Accounting} />
          ))}
        </div>
        <div className='w-full md:w-1/3 ml-0 md:ml-2'>
          {accountingColumn3.map((item, index) => (
            <CardAccounting key={index} accounting={item as Accounting} />
          ))}
        </div>
      </div>

      <CreateAccounting
        showModal={showCreateAccounting}
        toggleModal={toggleShowCreateAccounting}
      />
    </>
  )
}
