import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Budget, BudgetAmount, Sheet } from '../../../../lib/Types'
import * as API from '../API/BudgetAPI'

interface UseBudgetAmountsResult {
  data?: BudgetAmount[]
}

export const useCreateBudgetFromTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => API.createBudget(true),
    onSuccess: (newSheet: Sheet) => {
      queryClient.invalidateQueries({ queryKey: ['task'] })
      queryClient.setQueryData(['budgets'], (oldData: Sheet[]) => [
        ...oldData,
        newSheet,
      ])
    },
  })
}
export const useCreateBudgetFromScratch = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => API.createBudget(false),
    onSuccess: (newSheet: Sheet) => {
      queryClient.invalidateQueries({ queryKey: ['task'] })
      queryClient.setQueryData(['budgets'], (oldData: Sheet[]) => [
        ...oldData,
        newSheet,
      ])
    },
  })
}

export const useBudgetAmounts = (budget: Budget): UseBudgetAmountsResult => {
  return useQuery({
    queryKey: ['budgets', budget.id, 'amounts'],
    queryFn: () => API.getBudgetAmounts(budget),
    staleTime: Infinity,
  })
}

export const useBudget = (): UseQueryResult<Budget> => {
  const params = useParams()

  if (!params.budgetId) {
    throw Error('Budget ID is missing from params')
  }

  const budgetId = parseInt(params.budgetId, 10)

  return useQuery({
    queryKey: ['budgets', budgetId],
    queryFn: () => API.getBudget(budgetId),
    staleTime: Infinity,
  })
}

export const useBudgets = (): UseQueryResult<Budget[]> => {
  return useQuery({
    queryKey: ['budgets'],
    queryFn: () => API.getBudgets(),
    staleTime: Infinity,
  })
}
