import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Headline from '../../Elements/Headline'
import { Modal } from '../../Elements/Modal'
import Text from '../../Elements/Text'
import Button from '../../Formik/Button'
import {
  useCreateBudgetFromScratch,
  useCreateBudgetFromTemplate,
} from '../lib/Hooks/Budget'

const CreateBudget = () => {
  const {
    mutateAsync: createBudgetFromTemplate,
    isPending: isCreateFromTemplateLoading,
  } = useCreateBudgetFromTemplate()
  const {
    mutateAsync: createBudgetFromScratch,
    isPending: isCreateFromScratchLoading,
  } = useCreateBudgetFromScratch()

  const [showModal, toggleModal] = useState(false)
  const navigate = useNavigate()

  const fromTemplate = async () => {
    const budget = await createBudgetFromTemplate()

    return navigate(`/budget/${budget.id}/`)
  }

  const fromScratch = async () => {
    const budget = await createBudgetFromScratch()
    return navigate(`/budget/${budget.id}/`)
  }

  return (
    <>
      <Modal show={showModal} onHide={() => toggleModal(false)} wide>
        <Headline className='mb-5'>Nyt Budget</Headline>
        <div className='flex'>
          <div className='w-1/2 pr-5 border-r'>
            <Headline size='lg'>Opret fra en skabelon</Headline>
            <img
              src='/images/budget_template.png'
              className='w-full my-5 border'
            />
            <Text className='mb-5'>
              Hvis du ønsker et godt udgangspunkt for dit budget, kan du vælge
              at starte fra vores skabelon. Så indeholder dit budget alle
              relevante kategorier fra start.
            </Text>
            <Button
              onClick={fromTemplate}
              loading={isCreateFromTemplateLoading}
            >
              Vælg
            </Button>
          </div>
          <div className='w-1/2 pl-5 flex flex-col'>
            <Headline size='lg'>Opret et tomt budget</Headline>
            <img
              src='/images/budget_scratch.png'
              className='w-full my-5 border'
            />
            <Text className='mb-5'>
              Opret et tomt budget, hvis du selv vil have fuld kontrol over
              kategorierne i dit budget.
            </Text>
            <Button onClick={fromScratch} loading={isCreateFromScratchLoading}>
              Vælg
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        onClick={() => toggleModal(true)}
        className='ml-0 md:ml-2 mb-4 md:mb-0'
      >
        Opret et Budget
      </Button>
    </>
  )
}

export default CreateBudget
