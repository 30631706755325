import { Form, Formik, useFormikContext } from 'formik'
import { ChangeEvent } from 'react'
import * as Yup from 'yup'
import { getItemAmountForPeriod } from '../../../lib/Calculator'
import { getDateFromYearAndMonth } from '../../../lib/Dates'
import {
  SheetCategory,
  Spend,
  SpendAmount,
  SpendAmountCreate,
  SpendUpdate,
} from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Button from '../../Formik/Button'
import Input from '../../Formik/Input'
import Select from '../../Formik/Select'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { SpendType } from '../lib/Enums/SpendType'
import { useBudget } from '../lib/Hooks/Budget'
import { useCategories } from '../lib/Hooks/Categories'

interface Props {
  spend: Spend
  saveSpend: (spend: SpendUpdate) => void
}

const SpendConfiguration = ({ spend, saveSpend }: Props) => {
  const { data: budget } = useBudget()
  const { data: categories } = useCategories(budget!)

  return (
    <>
      <Headline className='mb-5'>
        Rediger {spend.type === SpendType.EXPENSE ? 'udgift' : 'indtægt'}
      </Headline>
      <Formik
        initialValues={{
          title: spend.title,
          category: spend.category ? spend.category.id : '',
          description: spend.description || '',
          amount: parseFloat(spend.amounts.at(-1)!.amount.toFixed(2)),
          payment_month: new Date(spend.accounting_date).getMonth() + 1,
          frequency: spend.amounts.at(-1)?.frequency,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Påkrævet'),
          category: Yup.string(),
          description: Yup.string().optional(),
          payment_month: Yup.string().required('Påkrævet'),
        })}
        onSubmit={(values) => {
          const amounts = structuredClone(spend.amounts) as SpendAmountCreate[]
          const latestAmount = amounts.at(-1)! as SpendAmount

          if (
            latestAmount.amount !== values.amount ||
            latestAmount.frequency !== values.frequency
          ) {
            amounts.push({
              formula: values.amount.toString(),
              frequency: values.frequency,
            })
          }

          const newSpend = {
            ...spend,
            amounts,
            title: values.title,
            description: values.description,
            accounting_date: getDateFromYearAndMonth(
              new Date().getFullYear(),
              values.payment_month,
            ),
          }

          if (spend.type === SpendType.EXPENSE) {
            newSpend.category = categories!.find(
              (category) =>
                category.id === parseInt(values.category as string, 10),
            ) as SheetCategory
          }

          saveSpend(newSpend)
        }}
      >
        <SpendConfigurationForm spend={spend} />
      </Formik>
    </>
  )
}

const SpendConfigurationForm = ({ spend }: { spend: Spend }) => {
  const { data: budget } = useBudget()
  const { data: categories } = useCategories(budget!)
  const {
    values: { frequency },
    setFieldValue,
  } = useFormikContext<any>()

  const handleFrequencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('frequency', event.target.value)
    setFieldValue(
      'amount',
      getItemAmountForPeriod(spend, event.target.value as SpendFrequency),
    )
  }
  const hasParent = () => {
    return !!spend.parent_id
  }

  return (
    <Form>
      <p className='mb-2'>
        Navn på {spend.type === SpendType.EXPENSE ? 'udgift' : 'indtægt'}
      </p>
      <Input
        name='title'
        placeholder='Angiv navn'
        size='sm'
        className='w-full mb-3'
      />
      <p className='mb-2'>Note</p>
      <Input
        name='description'
        placeholder='Skriv note'
        size='sm'
        className='w-full mb-3'
      />
      {spend.type === SpendType.EXPENSE && !hasParent() && (
        <>
          <p className='mb-2'>Vælg kategori</p>
          <Select name='category' className='mb-4'>
            {categories!.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
        </>
      )}
      {spend.type === SpendType.EXPENSE && (
        <>
          <p className='mb-2'>Beløb</p>
          <Input
            name='amount'
            type='number'
            placeholder='Beløb'
            size='sm'
            className='w-full mb-3'
          />
        </>
      )}
      <p className='mb-2'>
        {spend.type === SpendType.EXPENSE
          ? 'Betalingsfrekvens'
          : 'Indtægtsfrekvens'}
      </p>
      <Select
        name='frequency'
        className='mb-3'
        onChange={handleFrequencyChange}
      >
        <option value={SpendFrequency.MONTHLY}>Månedlig</option>
        <option value={SpendFrequency.BIMONTHLY}>Hver 2. måned</option>
        <option value={SpendFrequency.QUARTERLY}>Kvartalvis</option>
        <option value={SpendFrequency.BIYEARLY}>Halvårlig</option>
        <option value={SpendFrequency.YEARLY}>Årlig</option>
      </Select>

      {spend.type === SpendType.EXPENSE &&
        frequency === SpendFrequency.YEARLY && (
          <>
            <p className='mb-2'>Vælg betalingsmåned</p>
            <Select name='payment_month' className='mb-4'>
              <option value='1'>Januar</option>
              <option value='2'>Februar</option>
              <option value='3'>Marts</option>
              <option value='4'>April</option>
              <option value='5'>Maj</option>
              <option value='6'>Juni</option>
              <option value='7'>Juli</option>
              <option value='8'>August</option>
              <option value='9'>September</option>
              <option value='10'>Oktober</option>
              <option value='11'>November</option>
              <option value='12'>December</option>
            </Select>
          </>
        )}
      {spend.type === SpendType.EXPENSE &&
        frequency === SpendFrequency.BIYEARLY && (
          <>
            <p className='mb-2'>Vælg betalingsmåneder</p>
            <Select name='payment_month' className='mb-4'>
              <option value='1'>Januar, Juli</option>
              <option value='2'>Februar, August</option>
              <option value='3'>Marts, September</option>
              <option value='4'>April, Oktober</option>
              <option value='5'>Maj, November</option>
              <option value='6'>Juni, December</option>
            </Select>
          </>
        )}
      {spend.type === SpendType.EXPENSE &&
        frequency === SpendFrequency.QUARTERLY && (
          <>
            <p className='mb-2'>Vælg betalingsmåneder</p>
            <Select name='payment_month' className='mb-4'>
              <option value='1'>Januar, April, Juli, Oktober</option>
              <option value='2'>Februar, Maj, August, November</option>
              <option value='3'>Marts, Juni, September, December</option>
            </Select>
          </>
        )}
      {spend.type === SpendType.EXPENSE &&
        frequency === SpendFrequency.BIMONTHLY && (
          <>
            <p className='mb-2'>Vælg betalingsmåneder</p>
            <Select name='payment_month' className='mb-4'>
              <option value='1'>
                Januar, Marts, Maj, Juli, September, November
              </option>
              <option value='2'>
                Februar, April, Juni, August, Oktober, December
              </option>
            </Select>
          </>
        )}
      <div className='flex justify-end'>
        <Button type='submit' className='mt-3'>
          Gem
        </Button>
      </div>
    </Form>
  )
}

export default SpendConfiguration
