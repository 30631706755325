import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import * as Yup from 'yup'
import {
  convertAmountToPeriod,
  getItemAmountForPeriod,
} from '../../../lib/Calculator'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { UserType } from '../../../lib/Enums/UserType'
import { Spend } from '../../../lib/Types'
import Distribution from '../../Distribution'
import { DistributionType } from '../lib/Enums/DistributionType'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { SpendType } from '../lib/Enums/SpendType'
import { useBudget } from '../lib/Hooks/Budget'
import { useUpdateSpend } from '../lib/Hooks/Spends'

interface Props {
  spend: Spend
  isSpendExpanded?: boolean
  types?: DistributionType[]
}

enum activeUser {
  NONE,
  PRIMARY,
  SECONDARY,
}

const getItemAmountForPeriodForUserPercentage = (
  spend: Spend,
  user: UserType,
): number => {
  const amount = getItemAmountForPeriod(spend, SpendFrequency.MONTHLY)

  const multiplier =
    user === UserType.PRIMARY
      ? spend.distribution_primary_user
      : 100 - spend.distribution_primary_user

  return (amount / 100) * multiplier
}

const getItemAmountForPeriodForUserCurrency = (
  spend: Spend,
  user: UserType,
): number => {
  const amount =
    user === UserType.PRIMARY
      ? spend.distribution_primary_user
      : spend.distribution_secondary_user

  return convertAmountToPeriod(
    { amount, frequency: spend.amounts.at(-1)!.frequency },
    SpendFrequency.MONTHLY,
  )
}

const getCatoryAmountForPeriod = (spend: Spend, user: UserType): number => {
  let amount =
    spend.distribution_type === DistributionType.PERCENTAGE
      ? getItemAmountForPeriodForUserPercentage(spend, user)
      : getItemAmountForPeriodForUserCurrency(spend, user)

  if (spend.children) {
    spend.children.forEach((child) => {
      amount +=
        spend.distribution_type === DistributionType.PERCENTAGE
          ? getItemAmountForPeriodForUserPercentage(child, user)
          : getItemAmountForPeriodForUserCurrency(child, user)
    })
  }

  return amount
}

const SpendUserColumns: FC<Props> = ({
  spend,
  isSpendExpanded = false,
  types = [DistributionType.PERCENTAGE, DistributionType.CURRENCY],
}) => {
  const { data: budget } = useBudget()
  const [isActive, setIsActive] = useState(activeUser.NONE)
  const { mutateAsync: updateSpend } = useUpdateSpend(budget!)

  const saveChangeForExpense = (
    distributionType: DistributionType,
    amount: number,
    user: UserType,
  ) => {
    let amountForPrimaryUser: number, amountForSecondaryUser: number

    if (distributionType === DistributionType.PERCENTAGE) {
      amountForPrimaryUser = user === UserType.PRIMARY ? amount : 100 - amount
      amountForSecondaryUser = user === UserType.PRIMARY ? 100 - amount : amount
    }

    if (distributionType === DistributionType.CURRENCY) {
      amountForPrimaryUser =
        user === UserType.PRIMARY
          ? amount
          : spend.amounts.at(-1)!.amount - amount
      amountForSecondaryUser =
        user === UserType.PRIMARY
          ? spend.amounts.at(-1)!.amount - amount
          : amount
    }

    updateSpend({
      ...spend,
      distribution_type: distributionType,
      distribution_primary_user: amountForPrimaryUser!,
      distribution_secondary_user: amountForSecondaryUser!,
    })
  }

  const saveChangeForIncome = (amount: number, user: UserType) => {
    const newSpend = { ...spend }

    if (user === UserType.PRIMARY) {
      newSpend.distribution_primary_user = amount
    }
    if (user === UserType.SECONDARY) {
      newSpend.distribution_secondary_user = amount
    }

    updateSpend({
      ...newSpend,
    })
  }

  const getAmount = (userType: UserType) => {
    let amount = 0

    // If the spend is not expanded, and the spend is a parent, then it should show the total for the children.
    // If the spend is not expanded, and it is not a parent, then it means that the parent spend is expanded (otherwise it would never render)
    // ant therefore it should show its own amount
    if (!isSpendExpanded) {
      if (!spend.parent_id) {
        amount = getCatoryAmountForPeriod(spend, userType)
      } else {
        amount =
          spend.distribution_type === DistributionType.PERCENTAGE
            ? getItemAmountForPeriodForUserPercentage(spend, userType)
            : getItemAmountForPeriodForUserCurrency(spend, userType)
      }
    } else if (isSpendExpanded) {
      amount =
        spend.distribution_type === DistributionType.PERCENTAGE
          ? getItemAmountForPeriodForUserPercentage(spend, userType)
          : getItemAmountForPeriodForUserCurrency(spend, userType)
    }

    return formatCurrency(amount)
  }

  return (
    <>
      <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
        {isActive === activeUser.PRIMARY && (
          <Formik
            initialValues={{
              distribution_type: spend.distribution_type,
              distribution_primary_user: spend.distribution_primary_user,
            }}
            validationSchema={Yup.object({
              distribution_type: Yup.string().required('Påkrævet'),
              distribution_primary_user: Yup.number().required('Påkrævet'),
            })}
            onSubmit={(values) => {
              spend.type === SpendType.EXPENSE
                ? saveChangeForExpense(
                    values.distribution_type,
                    values.distribution_primary_user,
                    UserType.PRIMARY,
                  )
                : saveChangeForIncome(
                    values.distribution_primary_user,
                    UserType.PRIMARY,
                  )
              setIsActive(activeUser.NONE)
            }}
          >
            <Form>
              <Distribution
                nameInput='distribution_primary_user'
                types={types}
                max={
                  spend.type === SpendType.EXPENSE
                    ? spend.amounts.at(-1)!.amount
                    : false
                }
              />
            </Form>
          </Formik>
        )}
        {isActive !== activeUser.PRIMARY && (
          <span onClick={() => setIsActive(activeUser.PRIMARY)}>
            {getAmount(UserType.PRIMARY)}
          </span>
        )}
      </td>
      {budget!.user && (
        <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
          {isActive === activeUser.SECONDARY && (
            <Formik
              initialValues={{
                distribution_type: spend.distribution_type,
                distribution_secondary_user: spend.distribution_secondary_user,
              }}
              validationSchema={Yup.object({
                distribution_type: Yup.string().required('Påkrævet'),
                distribution_secondary_user: Yup.number().required('Påkrævet'),
              })}
              onSubmit={(values) => {
                spend.type === SpendType.EXPENSE
                  ? saveChangeForExpense(
                      values.distribution_type,
                      values.distribution_secondary_user,
                      UserType.SECONDARY,
                    )
                  : saveChangeForIncome(
                      values.distribution_secondary_user,
                      UserType.SECONDARY,
                    )
                setIsActive(activeUser.NONE)
              }}
            >
              <Form>
                <Distribution
                  nameInput='distribution_secondary_user'
                  types={types}
                  max={
                    spend.type === SpendType.EXPENSE
                      ? spend.amounts.at(-1)!.amount
                      : false
                  }
                />
              </Form>
            </Formik>
          )}
          {isActive !== activeUser.SECONDARY && (
            <span onClick={() => setIsActive(activeUser.SECONDARY)}>
              {getAmount(UserType.SECONDARY)}
            </span>
          )}
        </td>
      )}
    </>
  )
}

export default SpendUserColumns
