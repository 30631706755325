import { Form, Formik, useFormikContext } from 'formik'
import { FC } from 'react'
import {
  MdMoneyOff,
  MdOutlineCalculate,
  MdOutlineReceiptLong,
} from 'react-icons/md'
import * as Yup from 'yup'
import { formatDate } from '../../../lib/Dates'
import { Spend, SpendAmountCreate } from '../../../lib/Types'
import { isCurrency } from '../../../lib/Validations'
import { Modal } from '../../Elements/Modal'
import Button from '../../Formik/Button'
import Checkbox from '../../Formik/Checkbox'
import Input from '../../Formik/Input'
import Select from '../../Formik/Select'
import { SpendType } from '../lib/Enums/SpendType'
import { useAccounting } from '../lib/Hooks/Accounting'
import { useCategories } from '../lib/Hooks/Categories'
import {
  useAddSpend,
  useDeleteSpend,
  useUpdateSpend,
} from '../lib/Hooks/Spends'

interface Props {
  hideModal: () => void
  show: boolean
  spend?: Spend
  accountingDate?: Date | null
  title?: string | null
  category?: number | null
}

const SpendForm: FC<Props> = ({
  show,
  hideModal,
  spend,
  accountingDate,
  category,
}) => {
  const { data: accounting } = useAccounting()
  const { data: categories } = useCategories(accounting!)
  const { mutateAsync: addSpend, isPending: isAdding } = useAddSpend(
    accounting!,
  )
  const { mutate: updateSpend, isPending: isUpdating } = useUpdateSpend(
    accounting!,
  )

  const defaultCategory = category || categories!.at(0)?.id.toString()

  return (
    <>
      <Modal show={show} onHide={hideModal}>
        {(closeModal: any) => (
          <Formik
            initialValues={{
              title: spend ? spend.title : '',
              formula: spend
                ? spend.amounts.at(-1)!.formula.replaceAll('.', ',')
                : '',
              accounting_date: spend
                ? formatDate(new Date(spend.accounting_date), 'yyyy-mm-dd')
                : formatDate(new Date(), 'yyyy-mm-dd'),
              category: spend ? spend.category.id : defaultCategory,
              is_expense: spend ? spend.is_expense : false,
              expense_name: spend ? spend.expense_name : '',
              expense_paid: spend ? spend.expense_paid : false,
              exclude_from_summing: spend ? !spend.exclude_from_summing : true,
            }}
            validationSchema={Yup.object({
              title: Yup.string(),
              formula: Yup.string()
                .transform((currentValue) => {
                  // First remove all danish thousand seperators (.) and then replace the danish delimiter seperator (,) with the english one (.).
                  // This way it's possible to save floats.
                  return currentValue.replaceAll('.', '').replaceAll(',', '.')
                })
                .required('Påkrævet')
                .test(isCurrency),
              accounting_date: Yup.date().required('Påkrævet'),
              category: Yup.string().required('Påkrævet'),
              exclude_from_summing: Yup.boolean(),
              expense_paid: Yup.boolean(),
            })}
            onSubmit={async (values) => {
              const formula = values.formula
                .replaceAll('.', '')
                .replaceAll(',', '.')
              const spendAmount = { formula }
              const title = values.title || formatDate(new Date())
              const category = categories!.find(
                (category) =>
                  category.id === parseInt(values.category as string, 10),
              )

              if (spend) {
                const amounts = structuredClone(
                  spend.amounts,
                ) as SpendAmountCreate[]

                if (spendAmount.formula !== spend.amounts.at(-1)!.formula) {
                  amounts.push(spendAmount)
                }

                // Compare if the accounting date has changed.
                let accountingDate = new Date(spend.accounting_date)
                if (
                  values.accounting_date !==
                  formatDate(accountingDate, 'yyyy-mm-dd')
                ) {
                  const [year, month, day] = values.accounting_date.split('-')
                  accountingDate = new Date()
                  accountingDate.setFullYear(Number(year))
                  accountingDate.setMonth(Number(month) - 1, Number(day))
                }

                updateSpend({
                  ...spend,
                  ...values,
                  title,
                  amounts,
                  accounting_date: accountingDate,
                  category: category!,
                  exclude_from_summing: !values.exclude_from_summing,
                })

                closeModal()
              } else {
                const newSpend = {
                  ...values,
                  type: SpendType.EXPENSE,
                  title,
                  amounts: [spendAmount],
                  category: category!,
                  exclude_from_summing: !values.exclude_from_summing,
                  accounting_date: accountingDate,
                }

                await addSpend(newSpend)

                closeModal()
              }
            }}
          >
            <CreateSpendForm
              spend={spend}
              hideModal={hideModal}
              isLoading={isAdding || isUpdating}
              hideCategory={!!category}
            />
          </Formik>
        )}
      </Modal>
    </>
  )
}

interface CreateSpendFormProps {
  hideModal: () => void
  spend?: Spend
  isLoading: boolean
  hideCategory?: boolean
}

const CreateSpendForm: FC<CreateSpendFormProps> = ({
  spend,
  hideModal,
  isLoading,
  hideCategory = false,
}) => {
  const { data: accounting } = useAccounting()
  const { data: categories } = useCategories(accounting!)
  const {
    values: { is_expense },
    setFieldValue,
  } = useFormikContext<any>()
  const { mutate: deleteSpend, isPending: isDeleting } = useDeleteSpend(
    accounting!,
  )

  return (
    <Form>
      <p className='mt-5 mb-2'>Navn</p>
      <Input name='title' placeholder='Angiv navnet på udgiften'></Input>
      <p className='mt-5 mb-2'>Beløb</p>
      <div className='flex items-center'>
        <span className='mr-2'>=</span>
        <Input
          type='text'
          name='formula'
          placeholder='Angiv beløbet'
          className='grow'
          onBlur={(event) => {
            setFieldValue('formula', event.target.value.replaceAll(' ', ''))
          }}
        />
      </div>
      {spend && (
        <>
          <p className='mt-5 mb-2'>Dato</p>
          <Input type='date' name='accounting_date' />
        </>
      )}
      {!hideCategory && (
        <>
          <p className='mt-5'>Kategory</p>
          <Select name='category' className='mb-5'>
            {categories!.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
        </>
      )}
      <div className='mt-5'>
        <Checkbox name='exclude_from_summing'>
          <MdOutlineCalculate className='mr-2' /> Inkluder denne postering i
          forbrug for perioden
        </Checkbox>
        <Checkbox name='is_expense'>
          <MdMoneyOff className='mr-2' /> Denne postering er et udlæg
        </Checkbox>
      </div>
      {is_expense && (
        <>
          <Checkbox name='expense_paid'>
            <MdOutlineReceiptLong className='mr-2' /> Denne postering er betalt
          </Checkbox>
          <p className='mt-5 mb-2'>Udlægshaver</p>
          <Input
            name='expense_name'
            placeholder='Angiv navnet på den der er lagt ud for'
          ></Input>
        </>
      )}
      <Button type='submit' className='mt-5' loading={isLoading}>
        {spend ? 'Gem' : 'Tilføj'}
      </Button>
      {spend && (
        <Button
          className='float-right mt-5'
          theme='red'
          loading={isDeleting}
          onClick={async () => {
            if (
              window.confirm(`Er du sikker på at du vil slette ${spend.title}?`)
            ) {
              deleteSpend(spend)
              document.body.classList.remove('overflow-hidden')
              hideModal()
            }
          }}
        >
          Slet
        </Button>
      )}
    </Form>
  )
}

export default SpendForm
